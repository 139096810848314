import { FunctionComponent } from "react"

import FaqEntry, { Props as FaqEntryProps } from "components/Faq/FaqEntry"

import "./Faq.scss"

interface Props {
    entries: FaqEntryProps[]
}

const Faq: FunctionComponent<Props> = (props) => {
    return <>
        <div className="faq card">
            {
                props.entries.map((it, index) => (
                    <FaqEntry
                        key={ index }    
                        question={ it.question }  
                        answer={ it.answer } 
                        showAnswer={ it.showAnswer } 
                        onQuestionClick={ it.onQuestionClick }
                    />
                ))
            }
        </div>
    </>
}

export default Faq