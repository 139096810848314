import { ChangeEvent, FunctionComponent, useState } from "react"
import { useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiWeb } from "@mdi/js"
import _ from "lodash"

import "./ChangeDisplayLanguage.scss"

const displayLanguages = [ "en", "ro" ]

const ChangeDisplayLanguage: FunctionComponent = (props) => {
    const { i18n } = useTranslation()

    const [ isOpen, setIsOpen ] = useState<boolean>(false)
    const [ selectedLang, setSelectedLang ] = useState<string>(i18n.resolvedLanguage)

    const handleLanguageChange = (event: ChangeEvent<HTMLSelectElement>) => {
        i18n.changeLanguage(event.target.value).then()
        setSelectedLang(event.target.value)
        setIsOpen(false)
    }

    return <>
        {
            !isOpen
            ? <>
                <div className="change-display-language is-flex is-align-items-center" onClick={ () => setIsOpen(true) }>
                    <div className="icon">
                        <Icon path={ mdiWeb } size={ 1 } color="white"/>
                    </div>
                </div>
            </>
            : <>
                <div className="change-display-language-background" onClick={ () => setIsOpen(false) } />
                <div className="change-display-language is-flex is-align-items-center">
                    <div className="control is-narrow has-icons-left">
                        <div className="select">
                            <select name="lang" value={ selectedLang } onChange={ handleLanguageChange }>
                                {
                                    displayLanguages.map((value) => {
                                        return (
                                            <option key={ value } value={ value }>
                                                { _.toUpper(value) }
                                            </option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="icon is-small is-left">
                            <Icon path={ mdiWeb } size={ 1 } color="#6c0daf"/>
                        </div>
                    </div>
                </div>
            </>
        }
    </>
}

export default ChangeDisplayLanguage