import { FunctionComponent } from "react"
import Icon from "@mdi/react"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"

import "./FaqEntry.scss"

export interface Props {
    question: string
    answer: string | JSX.Element
    showAnswer: boolean
    onQuestionClick: () => void
}

const FaqEntry: FunctionComponent<Props> = (props) => {
    return <>
        <div className="faq-entry">
            <div className="card-header">
                <div className="card-header-title">
                    { props.question }
                </div>
                <button
                    className="card-header-icon"
                    onClick={ props.onQuestionClick }
                >
                <span className="icon">
                    <Icon path={ props.showAnswer ? mdiChevronUp : mdiChevronDown } size={ 1 } />
                </span>
                </button>
            </div>

            {
                props.showAnswer
                && <>
                    <div className="card-content">
                        { props.answer }
                    </div>
                </>
            }
        </div>
    </>
}

export default FaqEntry