import axios from "axios"

import Doctor, { IDoctorJson } from "data/Doctor"
import DoctorPresentationDetails, { IDoctorPresentationDetailsJson } from "data/DoctorPresentationDetails"

interface IGetDoctorPresentationV2ResponseJson {
    doctor: IDoctorJson
    presentation: IDoctorPresentationDetailsJson
    isFavorited: boolean
}

interface IGetDoctorPresentationV2Response {
    doctor: Doctor
    presentation: DoctorPresentationDetails
    isFavorited: boolean
}

export const getDoctorPresentationV2 = async (doctorId: string, language: string): Promise<IGetDoctorPresentationV2Response> => {
    const response = await axios.get<IGetDoctorPresentationV2ResponseJson>(`/v2/doctors/${doctorId}/presentation?display-language=${language}`)
    return {
        doctor: Doctor.fromJson(response.data.doctor),
        presentation: DoctorPresentationDetails.fromJson(response.data.presentation),
        isFavorited: response.data.isFavorited
    }
}