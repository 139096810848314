import { DateTime } from "luxon";

export interface IDoctorJson {
    id: string,
    profilePicture: string,
    givenName: string,
    familyName: string,
    primarySpecialty: number,
    firstYearOfMedicalExperience: string,
    spokenLanguages: string [],
    onlineConsultationsOverCountApproximation: number,
    scheduleBegin: string,
    scheduleEnd: string,
    scheduleTimeZone: string,
    ratePerMinuteInUnit: number,
    outOfScheduleRatePerMinuteInUnit: number,
    videoRatePerMinuteInUnit: number,
    acceptsOutsideOfSchedule: boolean,
    score: number,
    acceptsFreeFirstCalls: boolean,
    quote: Record<string, string>
}

class Doctor {
    constructor(
        public id: string,
        public profilePicture: string,
        public givenName: string,
        public familyName: string,
        public primarySpecialty: number,
        public firstYearOfMedicalExperience: DateTime,
        public spokenLanguages: string [],
        public onlineConsultationsOverCountApproximation: number,
        public scheduleBegin: DateTime,
        public scheduleEnd: DateTime,
        public scheduleTimeZone: string,
        public ratePerMinuteInUnit: number,
        public outOfScheduleRatePerMinuteInUnit: number,
        public videoRatePerMinuteInUnit: number,
        public acceptsOutsideOfSchedule: boolean,
        public score: number,
        public acceptsFreeFirstCalls: boolean,
        public quote: Record<string, string>
    ) {}

    static fromJson(json: IDoctorJson) {
        return new Doctor(
            json.id,
            json.profilePicture,
            json.givenName,
            json.familyName,
            json.primarySpecialty,
            DateTime.fromISO(json.firstYearOfMedicalExperience).toUTC(),
            json.spokenLanguages,
            json.onlineConsultationsOverCountApproximation,
            DateTime.fromISO(json.scheduleBegin).toUTC(),
            DateTime.fromISO(json.scheduleEnd).toUTC(),
            json.scheduleTimeZone,
            json.ratePerMinuteInUnit,
            json.outOfScheduleRatePerMinuteInUnit,
            json.videoRatePerMinuteInUnit,
            json.acceptsOutsideOfSchedule,
            json.score,
            json.acceptsFreeFirstCalls,
            json.quote
        )
    }
}

export default Doctor