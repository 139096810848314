import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import Icon from "@mdi/react"
import { FunctionComponent, useState } from "react"
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"

// components
import DoctorMediaCard from "components/Doctor/DoctorMediaCard/DoctorMediaCard"
import DoctorMediaPreview from "components/Doctor/DoctorMediaPreview/DoctorMediaPreview"

// lib
import DoctorMediaContent from "data/DoctorMediaContent"

// style
import "components/Doctor/DoctorMediaCarousel/DoctorMediaCarousel.scss"
import "pure-react-carousel/dist/react-carousel.es.css"

interface IProps {
    media: DoctorMediaContent[]
}

const DoctorMediaCarousel: FunctionComponent<IProps> = ({ media }) => {
    const [ isShowingPreviewOf, setIsShowingMediaPreviewOf ] = useState<number>()
    
    return <>
        <CarouselProvider
            className="doctor-media-carousel"
            naturalSlideHeight={ 9 }
            naturalSlideWidth={ 16 }
            totalSlides={ media.length }
            visibleSlides={ 1.3 }
            infinite={ true }
        >
            <Slider className="my-2">
                {
                    media.map((it, index) =>
                        <Slide 
                            key={ index } 
                            index={ index } 
                            innerClassName={ `innerSlide px-4` } 
                            onClick={ () => setIsShowingMediaPreviewOf(index) }
                        >
                            <DoctorMediaCard media={ it }/>
                        </Slide>
                    )
                }
            </Slider>

            <ButtonBack className="navigation-button prev button is-small is-circular">
                <span className="icon">
                    <Icon path={ mdiChevronLeft } />
                </span>
            </ButtonBack>
            <ButtonNext className="navigation-button next button is-small is-circular">
                <span className="icon">
                    <Icon path={ mdiChevronRight } />
                </span>
            </ButtonNext>
        </CarouselProvider>

        {
            isShowingPreviewOf !== undefined 
            && <>
                <DoctorMediaPreview
                    media={ media[isShowingPreviewOf] }
                    onClose={ () => setIsShowingMediaPreviewOf(undefined) }
                />
            </>
        }
    </>
}

export default DoctorMediaCarousel

