import { FunctionComponent, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import { mdiCheck, mdiContentCopy } from "@mdi/js"

import api from "service/api"

import Footer from "components/Footer/Footer"
import TopBar from "components/TopBar/TopBar"
import StoreLinks from "components/StoreLinks/StoreLinks"
import Faq from "components/Faq/Faq"

import "./CampaignCode.scss"
import AgathaLogo from "assets/agatha_logo_purple.svg"
import ColegiulPacientilorLogo from "assets/colegiul-pacientilor-logo.png"

interface Params {
    pageIdentifier: string
}

const CampaignCode: FunctionComponent = () => {
    const { t } = useTranslation("translation")
    const { pageIdentifier } = useParams<Params>()
    const history = useHistory()
    
    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ code, setCode ] = useState<string>("")
    const [ noCodesAvailable, setNoCodesAvailable ] = useState<boolean>(false)
    
    // UI
    const [ isShowingAnswerOf, setIsShowingAnswerOf ] = useState<number>(0)
    const [ copiedToClipboard, setCopiedToClipboard ] = useState<boolean>(false)
    
    useEffect(() => {
        const fetch = async () => {
            try {
                const response = await api.getRandomCampaignCode(pageIdentifier)
                
                if (response !== null)
                    setCode(`${response.slice(0,3)}-${response.slice(3)}`)
                else
                    setNoCodesAvailable(true)
                
            } catch(e) {
                history.replace("/")
            }
        }
        
        fetch().then(() => setIsLoading(false))
    }, [])
    
    const handleOpenAnswer = (index: number) => {
        setIsShowingAnswerOf(isShowingAnswerOf === index ? -1 : index)
    }
    
    const handleCopyToClipboard = async () => {
        await navigator.clipboard.writeText(code.replace('-', ''))
        
        setCopiedToClipboard(true)
        setTimeout(() => setCopiedToClipboard(false), 2000)
    }

    if (isLoading)
        return <>Loading...</>
    
    return <>
        <TopBar logoOnly={ true } />
        
        <div className="campaign-code container px-3">
            <div className="columns is-centered">
                <div className="column has-text-centered">
                    <p className="title my-4">{ t("campaignCode.title") }</p>

                    <br/>
                    
                    <div className="columns is-centered mb-0 is-gapless">
                        <div className="column is-narrow">
                            <div className="code-container is-flex is-justify-content-space-between is-align-items-center mr-0">
                                {
                                    (() => {
                                        if (code !== "")
                                            return <>
                                                <p className="code title is-1 mb-0">{ code }</p>
                                            </>

                                        if (noCodesAvailable)
                                            return <>
                                                <p>{ t("campaignCode.noCodesAvailable") }</p>
                                            </>
                                    })()
                                }

                                <button
                                    className={ `copy-to-clipboard-button button ${ isLoading || noCodesAvailable ? "hidden" : "" }` }
                                    onClick={ handleCopyToClipboard }
                                >
                                    <span className="icon">
                                        <Icon path={ copiedToClipboard ? mdiCheck : mdiContentCopy } size={ 1 } color={ copiedToClipboard ? "green" : "white" }/>
                                    </span>
                                </button>
                            </div>

                            
                        </div>
                    </div>
                    {
                        code !== ""
                        && <>
                            <p className="subtitle is-6 mt-1">{ t("campaignCode.expiredCodeHelp") }</p>
                        </>
                    }
                    
                    <br/>
                    
                    <p className="subtitle is-5">
                        <Trans
                            i18nKey="campaignCode.campaignDescription"
                        >
                            Colegiul Pacienților gives you free online access to doctors who are experts in nutrition, addiction, psychology and sex life.
                            <br/>
                            <br/>
                            You can safely and confidentially call on their expertise whenever you need it.
                            <br/>
                            <br/>
                            No cost. No appointment. All online and secure.
                        </Trans>
                    </p>
                    
                    
                    <br/>
                    
                    {/*download now button*/}
                    <p className="subtitle is-5">{ t("campaignCode.downloadNow") }</p>
                    <StoreLinks />
                    
                    <br/>

                    <p className="title has-text-left mt-6 mb-2 ml-2">FAQ</p>
                    <Faq 
                        entries={ [
                            {
                                question: t("campaignCode.faq.question1.question"),
                                answer: t("campaignCode.faq.question1.answer"),
                                showAnswer: isShowingAnswerOf === 1,
                                onQuestionClick: () => handleOpenAnswer(1)    
                            },
                            {
                                question: t("campaignCode.faq.question2.question"),
                                answer: t("campaignCode.faq.question2.answer"),
                                showAnswer: isShowingAnswerOf === 2,
                                onQuestionClick: () => handleOpenAnswer(2)
                            },
                            {
                                question: t("campaignCode.faq.question3.question"),
                                answer: t("campaignCode.faq.question3.answer"),
                                showAnswer: isShowingAnswerOf === 3,
                                onQuestionClick: () => handleOpenAnswer(3)
                            },
                            {
                                question: t("campaignCode.faq.question4.question"),
                                answer: <Trans
                                    i18nKey="campaignCode.faq.question4.answer"
                                >
                                    Colegiul Pacienților is one of the largest NGOs in Romania that promotes patients' rights and access to health services.
                                    <br/>
                                    <br/>
                                    We created this campaign together with Agatha Health, a revolutionary telemedicine platform to help young people more easily access doctors with expertise relevant to their problems.
                                </Trans>,
                                showAnswer: isShowingAnswerOf === 4,
                                onQuestionClick: () => handleOpenAnswer(4)
                            },
                        ] }
                    />
                    
                    <br/>
                    
                    <p className="title is-6 mt-6">{ t("campaignCode.campaignCredits") }</p>
                    
                    <div className="columns is-centered is-vcentered mb-2">
                        <div className="column is-narrow">
                            <img className="logo" src={ AgathaLogo } alt="agatha-health" />
                        </div>
                        <div className="column is-narrow">
                            <img className="logo" src={ ColegiulPacientilorLogo } alt="agatha-health" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <Footer />
    </>
}

export default CampaignCode