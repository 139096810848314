import DoctorCareerPoint from "./DoctorCareerPoint"
import DoctorMediaContent from "./DoctorMediaContent"

export interface IDoctorPresentationDetailsJson {
    careerPoints: DoctorCareerPoint[]
    description: string
    mediaContent: DoctorMediaContent[]
}
class DoctorPresentationDetails {
    constructor(
        public careerPoints: DoctorCareerPoint[],
        public description: string,
        public mediaContent: DoctorMediaContent[]
    ) {}
    
    public static fromJson(json: IDoctorPresentationDetailsJson) {
        return new DoctorPresentationDetails(
            json.careerPoints.map((it) => DoctorCareerPoint.fromJson(it)),
            json.description,
            json.mediaContent.map(it => DoctorMediaContent.fromJson(it))
        )
    }
}

export default DoctorPresentationDetails