import { FunctionComponent } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

import routes from "config/routes"

// assets
import "./Footer.scss"

const Footer: FunctionComponent = () => {
    const { t } = useTranslation()

    const currentYear = (new Date()).getFullYear()

    return <>
        <footer className="footer">
            <div className="container">
                <div className="links is-flex is-justify-content-center">
                    <Link to={ routes.privacyPolicy }>
                        { t("privacyPolicy.title") }
                    </Link>

                    <Link to={ routes.termsAndConditions }>
                        { t("termsAndConditions.title") }
                    </Link>

                    <Link to={  routes.gdpr }>
                        { t("gdpr.title") }
                    </Link>
                </div>

                <br/>

                <div className="content has-text-centered">
                    <p>
                        { t("footer.copyright", { year: currentYear > 2022 ? `2022 - ${ currentYear }` : 2022 }) }
                    </p>
                </div>
            </div>
        </footer>
    </>
}

export default Footer