import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
// import { DateTime } from "luxon"
// import _ from "lodash"

import "i18n-resources.d.ts"

i18n
    // i18next-http-backend
    // loads translations from your server
    // https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
        },
        debug: process.env.NODE_ENV === "development",
        fallbackLng: "en",
        load: "languageOnly",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            // format: (value, format, lng) => {
            //     if (value instanceof Date) {
            //         // custom formats
            //         if (format === "DATE_SHORT_WITHOUT_DAY")
            //             return DateTime.fromJSDate(value).setLocale(lng!).toLocaleString({ year: "2-digit", month: "numeric" })
            //
            //         if (format === "MONTH_ONLY_LONG")
            //             return _.upperFirst(DateTime.fromJSDate(value).setLocale(lng!).toLocaleString({ month: "long" }))
            //
            //         return DateTime.fromJSDate(value).setLocale(lng!).toLocaleString((DateTime as any)[format!])
            //     }
            //
            //     return value;
            // }
        }
    })

export default i18n