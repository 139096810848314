import React, { FunctionComponent, PropsWithChildren } from "react"
import { useMediaQuery } from 'react-responsive'

export const Portrait: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const isPortrait = useMediaQuery({ orientation: "portrait" })
    return <>
        { isPortrait && children }
    </>
}

export const Landscape: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const isLandscape = useMediaQuery({ orientation: "landscape" })
    return <>
        { isLandscape && children }
    </>
}

//
// export const Desktop: FunctionComponent<PropsWithChildren> = ({ children }) => {
//     const isDesktop = useMediaQuery({ minWidth: 992 })
//     return <>
//         { isDesktop && children }
//     </>
// }
//
// export const Tablet: FunctionComponent<PropsWithChildren> = ({ children }) => {
//     const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
//     return <>
//         { isTablet && children }
//     </>
// }
//
// export const Mobile: FunctionComponent<PropsWithChildren> = ({ children }) => {
//     const isMobile = useMediaQuery({ maxWidth: 767 })
//     return <>
//         { isMobile && children }
//     </>
// }
//
// export const Default: FunctionComponent<PropsWithChildren> = ({ children }) => {
//     const isNotMobile = useMediaQuery({ minWidth: 768 })
//     return <>
//         { isNotMobile && children }
//     </>
// }