import { FunctionComponent, useEffect, useState } from "react"
import { Link as RouterLink, useLocation } from "react-router-dom"
import { Link as ScrollLink, animateScroll } from "react-scroll"
import { useTranslation } from "react-i18next"
import { mdiArrowUpBold } from "@mdi/js"
import Icon from "@mdi/react"

// components
import ChangeDisplayLanguage from "components/ChangeDisplayLanguage/ChangeDisplayLanguage"

import routes from "config/routes"

// assets
import AgathaLogo from "assets/agatha_logo_purple.svg"
import "./TopBar.scss"

interface Props {
    logoOnly?: boolean
}

const TopBar: FunctionComponent<Props> = (props = { logoOnly: false }) => {
    const { t } = useTranslation()
    const location = useLocation()

    const [ isMenuOpen, setIsMenuOpen ] = useState<boolean>(false)
    const [ isScrollToTopHidden, setIsScrollToTopHidden ] = useState<boolean>(true)

    // add scroll event listener to hide/show
    // buttons (lang and scrollToTop)
    useEffect(() => {
        window.removeEventListener("scroll", handleScroll)
        window.addEventListener("scroll", handleScroll, { passive: true})

        return () => window.removeEventListener("scroll", handleScroll)
    }, [])

    // scroll to top on route change
    useEffect(() => {
        animateScroll.scrollToTop()
    }, [ location.pathname ])

    const handleScroll = (event: Event) => {
        setIsScrollToTopHidden(window.scrollY < 86)
    }

    return <>
        {/*newer version*/}
        <nav className="navbar topBar">
            <div className="container">
                <div className="navbar-brand">
                    <div className="navbar-item">
                        <RouterLink to={ routes.home }>
                            <img className="topBarLogo" src={AgathaLogo} alt="Agatha Logo" />
                        </RouterLink>
                    </div>
                    {
                        !props.logoOnly
                        && <>
                            <div
                                className={ `navbar-burger ${ isMenuOpen ? "is-active" : "" }` }
                                data-target="agatha-navbar"
                                onClick={ () => {
                                    setIsMenuOpen(!isMenuOpen)
                                } }
                            >
                                <span/>
                                <span/>
                                <span/>
                            </div>
                        </>
                    }
                </div>

                {
                    !props.logoOnly
                    && <>
                        <div id="agatha-navbar" className={ `navbar-menu ${ isMenuOpen ? "is-active" : "" }` }>
                            <div className="navbar-end is-flex is-align-items-center">
                                <div className="topBarLinks">
                                    <ScrollLink
                                        className="topBarLink"
                                        to="our-app"
                                        smooth={ true }
                                        duration={ 500 }
                                    >
                                        { t("navBar.links.ourApp") }
                                    </ScrollLink>
                                    <ScrollLink
                                        className="topBarLink"
                                        to="doctors"
                                        smooth={ true }
                                        duration={ 750 }
                                    >
                                        { t("navBar.links.doctors") }
                                    </ScrollLink>
                                    <ScrollLink
                                        className="topBarLink"
                                        to="lab-tests"
                                        smooth={ true }
                                        duration={ 1000 }
                                    >
                                        { t("navBar.links.labTests") }
                                    </ScrollLink>
                                    <ScrollLink
                                        className="topBarLink"
                                        to="contact-us"
                                        smooth={ true }
                                        duration={ 1250 }
                                    >
                                        { t("navBar.links.contactUs") }
                                    </ScrollLink>
                                    <RouterLink
                                        className="topBarLink"
                                        to={ routes.joinUs }
                                    >
                                        { t("navBar.links.joinUs") }
                                    </RouterLink>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
        </nav>

        {
            !isScrollToTopHidden
            && <>
                <div className="scroll-to-top is-flex is-align-items-center" onClick={ () => { animateScroll.scrollToTop() } }>
                    <Icon path={ mdiArrowUpBold } color="white" size={ 1 } />
                </div>
            </>
        }

        {
            isScrollToTopHidden
            && <ChangeDisplayLanguage />
        }
    </>
}

export default TopBar