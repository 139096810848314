const home = "/"
const passwordResetSuccess = "/password-reset-success"
const passwordResetFailed = "/password-reset-failed"
const privacyPolicy = "/privacy-policy"
const termsAndConditions = "/terms-and-conditions"
const gdpr = "/gdpr"
const doctors = "/doctors/:doctorId"
const campaignCode = "/campaign-code/:pageIdentifier"
const joinUs = "/join"
const deleteAccount = "/delete-account"

const doctorPage = (doctorId: string) => `/doctors/${doctorId}`
const campaignCodePage = (pageIdentifier: string) => `/campaign-code/${pageIdentifier}`

const routes = {
    home: home,
    passwordResetSuccess: passwordResetSuccess,
    passwordResetFailed: passwordResetFailed,
    privacyPolicy: privacyPolicy,
    termsAndConditions: termsAndConditions,
    gdpr: gdpr,
    doctors: doctors,
    doctorPage: doctorPage,
    campaignCode: campaignCode,
    campaignCodePage: campaignCodePage,
    joinUs: joinUs,
    deleteAccount: deleteAccount
}

export default routes