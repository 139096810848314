import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

interface Props {
    type: "success" | "failed"
}
const PasswordResetCard: FunctionComponent<Props> = (props) => {
    const { t } = useTranslation("translation")
    
    return <>
        <div className="card">
            <div className="card-content">
                {/* text */}
                <p className="title has-text-centered">
                    { 
                        props.type == "success"
                        && t("passwordReset.success.title")
                    }
                    {
                        props.type == "failed"
                        &&  t("passwordReset.failed.title")
                    }
                </p>
                <br/>
                <p className="subtitle has-text-centered">
                    { 
                        props.type == "success"
                        && t("passwordReset.success.message")
                    }
                    {
                        props.type == "failed"
                        && t("passwordReset.failed.message")
                    }
                </p>
            </div>
        </div>
    </>
}

export default PasswordResetCard