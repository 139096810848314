import { FunctionComponent, useEffect, useState } from "react"
import Icon from "@mdi/react"
import { mdiStar, mdiStarHalfFull, mdiStarOutline } from "@mdi/js"

interface Props {
    score: number
    size?: number
}

const starsFromScore = (score: number) => {
    return Math.round(score / 0.5) * 0.5
}

const Stars: FunctionComponent<Props> = ({ score, size = 1.5 }) => {
    const [ stars, setStars ] = useState<number>(starsFromScore(score))
    
    useEffect(() => {
        setStars(starsFromScore(score))
    }, [ score ])
    
    const renderStarIcon = (rangeBegin: number, rangeEnd: number) => {
        return <Icon 
            path={ 
                stars == rangeBegin 
                ? mdiStarHalfFull
                : stars >= rangeEnd 
                    ? mdiStar
                    : mdiStarOutline
            }
            size={ size }
        />
    }
    
    return <>
        { renderStarIcon(0.5, 1) }
        { renderStarIcon(1.5, 2) }
        { renderStarIcon(2.5, 3) }
        { renderStarIcon(3.5, 4) }
        { renderStarIcon(4.5, 5) }
    </>
}

export default Stars