import React, { Suspense } from "react"
import { createRoot } from "react-dom/client"
import { Router } from "react-router-dom"
import { createBrowserHistory } from "history"
import TagManager, { TagManagerArgs } from "react-gtm-module"

import { setUpAxios } from "service/service"

// components
import App from "App"

// assets
import "styles/index.scss"

// axios
setUpAxios()

// import i18n (needs to be bundled ))
import "./i18n"

const history = createBrowserHistory()

// Google Tag Manager
if (process.env.NODE_ENV === "production") {
    const tagManagerArgs: TagManagerArgs = {
        gtmId: 'GTM-PQ6VXLP'
    }

    TagManager.initialize(tagManagerArgs)
}

const root = createRoot(document.getElementById("root") as HTMLElement)
root.render(
    <Suspense fallback={ <div/> }>
        <Router history={ history }>
            <App />
        </Router>
    </Suspense>    
)

