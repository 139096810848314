import { zodResolver } from "@hookform/resolvers/zod"
import { mdiCheck, mdiSend } from "@mdi/js"
import Icon from "@mdi/react"
import { FunctionComponent, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import YouTube from "react-youtube"
import { z } from "zod"
import Footer from "../../components/Footer/Footer"
import TextInputField from "../../components/Form/TextInputField/TextInputField"
import TopBar from "../../components/TopBar/TopBar"
import useQuery from "../../hooks/useQuery"
import "./JoinUsPage.scss"
import { postDoctorJoinRequest } from "../../service/endpoints/PostDoctorJoinRequest"
import { getYouTubeVideoIdFromUrl } from "../../utils"

const schema = z.object({
    givenName: z.string(),
    familyName: z.string(),
    email: z.string().email(),
    phoneNumber: z.string().min(4),
    specialty: z.string(),
    spokenLanguages: z.string(),
    firstYearOfMedicalExperience: z.string()
})

type FormData = z.infer<typeof schema>

const joinUsVideoAd = "https://www.youtube.com/watch?v=BQKECl4NbBI"

const JoinUsPage: FunctionComponent = () => {
    const defaultValues = (): FormData => ({
        givenName: "",
        familyName: "",
        email: "",
        phoneNumber: "",
        specialty: "",
        spokenLanguages: "",
        firstYearOfMedicalExperience: ""
    })

    // hooks
    const { t, i18n } = useTranslation()
    const query = useQuery()
    const {
        register,
        handleSubmit,
        control,
        formState : {
            isDirty,
            isValid,
            isSubmitting
        }
    } = useForm<FormData>({
        resolver: zodResolver(schema),
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: defaultValues()
    })

    // state
    const [ isFormSent, setIsFormSent ] = useState<boolean>(false)

    // submit
    const onSubmit: SubmitHandler<FormData> = async (data) => {
        // submit form
        try {
            await postDoctorJoinRequest({
                request: data,
                from: query.get("from") ?? undefined,
                language: i18n.resolvedLanguage
            })
            setIsFormSent(true)
        }
        catch {
            // guess try again?
        }
    }

    return <>
        <TopBar logoOnly />

        <div className="join-us-page section">
            <div className="container">
                <div className="columns">
                    <div className="column is-three-fifths is-offset-one-fifth">
                        <div className="page-title">{ t("joinUs.title") }</div>

                        <p className="text-description my-5">
                            { t("joinUs.description.p1") }
                        </p>


                        <div className="mb-6">
                            <YouTube
                                videoId={ getYouTubeVideoIdFromUrl(joinUsVideoAd) }
                                opts={{
                                    playerVars: {
                                        autoplay: 1,
                                        loop: 1
                                    }
                                }}
                            />
                        </div>

                        <p className="text-description mt-6 mb-2">
                            { t("joinUs.description.p2") }
                        </p>
                    </div>
                </div>

                <div className="columns">
                    <div className="column is-three-fifths is-offset-one-fifth">
                        {/*form*/}
                        <form onSubmit={ handleSubmit(onSubmit) }>
                            <div className="field is-horizontal">
                                <div className="field-body">
                                    {/*given name*/}
                                    <TextInputField
                                        control={ control }
                                        name="givenName"
                                        label={ t("joinUs.form.givenName.label") }
                                    />

                                    {/*family name*/}
                                    <TextInputField
                                        control={ control }
                                        name="familyName"
                                        label={ t("joinUs.form.familyName.label") }
                                    />
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    {/*primary email*/}
                                    <TextInputField
                                        control={ control }
                                        name="email"
                                        label={ t("joinUs.form.email.label") }
                                    />

                                    {/*phone number*/}
                                    <TextInputField
                                        control={ control }
                                        name="phoneNumber"
                                        label={ t("joinUs.form.phoneNumber.label") }
                                    />
                                </div>
                            </div>

                            <div className="field is-horizontal">
                                <div className="field-body">
                                    {/*specialty*/}
                                    <TextInputField
                                        control={ control }
                                        name="specialty"
                                        label={ t("joinUs.form.specialty.label") }
                                    />

                                    {/*first year of medical experience*/}
                                    <TextInputField
                                        control={ control }
                                        name="firstYearOfMedicalExperience"
                                        label={ t("joinUs.form.firstYearOfMedicalExperience.label") }
                                    />
                                </div>
                            </div>

                            {/*spoken languages*/}
                            <TextInputField
                                control={ control }
                                name="spokenLanguages"
                                label={ t("joinUs.form.spokenLanguages.label") }
                            />

                            <div className="field is-grouped is-grouped-right">
                                <button
                                    className={ `button is-primary ${ isSubmitting && "is-loading" }` }
                                    type="submit"
                                    disabled={ (isDirty && !isValid) || !isDirty || isFormSent }
                                >
                                    <span className="icon">
                                        {
                                            isFormSent
                                                ? <Icon path={ mdiCheck } />
                                                : <Icon path={ mdiSend } />
                                        }
                                    </span>
                                    <span>
                                        {
                                            isFormSent
                                                ? t("joinUs.form.submitButton.sent")
                                                : t("joinUs.form.submitButton.send")
                                        }
                                    </span>
                                </button>
                            </div>
                        </form>

                        <p className="text-description mt-6 mb-2">
                            { t("joinUs.description.p3") }
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </>
}

export default JoinUsPage