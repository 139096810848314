import { Control, FieldPath, FieldValues, useController } from "react-hook-form"

type Props<TFieldValues extends FieldValues> = {
    control: Control<TFieldValues>
    name: FieldPath<TFieldValues>
    label: string
    placeholder?: string
}

const TextInputField = <TFieldValues extends FieldValues>({
    control,
    name,
    label,
    placeholder
}: Props<TFieldValues>) => {
    const { field, fieldState: { error }} = useController({ control: control, name: name })

    return (
        <div className="field">
            <label className="label">{ label }</label>
            <div className="control">
                <input
                    className={ `input ${ error && "is-danger" }` }
                    placeholder={ placeholder }
                    type="text"
                    { ...field }
                />
            </div>
            {
                error?.message && (
                    <p className="help is-danger">{ error.message } </p>
                )
            }
        </div>
    )
}

export default TextInputField