import { FunctionComponent, useEffect, useState } from "react"
import YouTube from "react-youtube"

// lib
import DoctorMediaContent from "data/DoctorMediaContent"
import DoctorMediaContentType from "enums/DoctorMediaContentType"
import { getYouTubeVideoIdFromUrl } from "utils"

// styles
import "components/Doctor/DoctorMediaPreview/DoctorMediaPreview.scss"

interface IYtSize {
    width: number,
    height: number
}

interface IProps {
    media: DoctorMediaContent
    onClose: () => void
}

const calcYtPlayerSize = (): IYtSize => {
    // 1320 because we account for the padding of at least 100px around the video
    const newWidth = window.innerWidth > 1380 ? 1280 : window.innerWidth - 100
    const newHeight = window.innerWidth < window.innerHeight
        ? (window.innerWidth / window.innerHeight) * newWidth
        : (window.innerHeight / window.innerWidth) * newWidth

    return {
        width: newWidth,
        height: newHeight
    }
}

const DoctorMediaPreview: FunctionComponent<IProps> = ({ media, onClose }) => {
    const [ ytSize, setYtSize ] = useState<IYtSize>(calcYtPlayerSize())

    const handleResize = () => {
        setYtSize(calcYtPlayerSize())
    }

    // resize event listener
    useEffect(() => {
        window.addEventListener("resize", handleResize)
        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return <>
        <div className="media-preview modal is-active">
            <div className="modal-background" onClick={ onClose } />
            <div className="modal-close is-large" onClick={ onClose } />
            <div className="modal-content is-flex is-justify-content-center is-align-items-center">
                {
                    (() => {
                        switch (media.type) {
                            case DoctorMediaContentType.Image:
                                return <>
                                    <img src={ media.url } alt="doctor-media-content-entry" />
                                </>

                            case DoctorMediaContentType.YoutubeVideo:
                            case DoctorMediaContentType.AgathaTV:
                                return <>
                                    <YouTube
                                        videoId={ getYouTubeVideoIdFromUrl(media.url) }
                                        opts={ {
                                            width: ytSize.width.toString(),
                                            height: ytSize.height.toString(),
                                            playerVars: {
                                                autoplay: 1
                                            }
                                        } }
                                    />
                                </>
                        }
                    })()
                }
            </div>
        </div>
    </>
}

export default DoctorMediaPreview