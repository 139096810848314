import { FunctionComponent, PropsWithChildren, useEffect } from "react"

interface Props {
    showCloseButton?: boolean
    onClose: () => void
}

const Modal: FunctionComponent<PropsWithChildren<Props>> = ({ showCloseButton = false, onClose, children }) => {
    // used to disable scrolling in the background
    useEffect(() => {
        document.documentElement.style.overflow = "hidden"
        document.documentElement.style.paddingRight = "15px"
        
        
        return () => {
            document.documentElement.style.overflow = ""
            document.documentElement.style.paddingRight = ""
        }
    }, [])
    
    return <>
        <div className="modal is-active">
            <div className="modal-background" onClick={ onClose } />
            <div className="modal-content">
                { children }
            </div>

            {
                showCloseButton 
                && <>
                    <button
                        className="modal-close is-large"
                        aria-label="close"
                        onClick={ onClose }
                    />
                </> 
            }
        </div>
    </>
}

export default Modal