interface ILanguageEntry {
    native: string
    ro: string
    en: string

    [key: string]: string
}

interface ILanguages {
    [key: string]: ILanguageEntry
}

const languages: ILanguages = {
    aa: { native: "Afar", ro: "Afar", en: "Afar" },
    af: { native: "Afrikaans", ro: "Afrikaans", en: "Afrikaans" },
    agq: { native: "Aghem", ro: "Aghem", en: "Aghem" },
    ak: { native: "Akan", ro: "Akan", en: "Akan" },
    am: { native: "አማርኛ", ro: "Amharică", en: "Amharic" },
    ar: { native: "العربية", ro: "Arabă", en: "Arabic" },
    arn: { native: "Mapudungun", ro: "Mapuche", en: "Mapuche" },
    as: { native: "অসমীয়া", ro: "Asameză", en: "Assamese" },
    asa: { native: "Kipare", ro: "Asu", en: "Asu" },
    ast: { native: "asturianu", ro: "Asturiană", en: "Asturian" },
    az: { native: "azərbaycan", ro: "Azeră", en: "Azerbaijani" },
    ba: { native: "башҡорт теле", ro: "Bașkiră", en: "Bashkir" },
    bas: { native: "Ɓàsàa", ro: "Basaa", en: "Basaa" },
    be: { native: "беларуская", ro: "Belarusă", en: "Belarusian" },
    bem: { native: "Ichibemba", ro: "Bemba", en: "Bemba" },
    bez: { native: "Hibena", ro: "Bena", en: "Bena" },
    bg: { native: "български", ro: "Bulgară", en: "Bulgarian" },
    bin: { native: "Ẹ̀dó", ro: "Bini", en: "Edo" },
    bm: { native: "bamanakan", ro: "Bambara", en: "Bamanankan" },
    bn: { native: "বাংলা", ro: "Bengaleză", en: "Bangla" },
    bo: { native: "བོད་སྐད་", ro: "Tibetană", en: "Tibetan" },
    br: { native: "brezhoneg", ro: "Bretonă", en: "Breton" },
    brx: { native: "बड़ो", ro: "Bodo", en: "Bodo" },
    bs: { native: "bosanski", ro: "Bosniacă", en: "Bosnian" },
    byn: { native: "Blin", ro: "Blin", en: "Blin" },
    ca: { native: "català", ro: "Catalană", en: "Catalan" },
    ccp: { native: "𑄌𑄋𑄴𑄟𑄳𑄦", ro: "Chakma", en: "Chakma" },
    ce: { native: "нохчийн", ro: "Cecenă", en: "Chechen" },
    ceb: { native: "Binisaya", ro: "Cebuană", en: "Cebuano" },
    cgg: { native: "Rukiga", ro: "Chiga", en: "Chiga" },
    chr: { native: "ᏣᎳᎩ", ro: "Cherokee", en: "Cherokee" },
    ckb: { native: "کوردیی ناوەندی", ro: "Kurdă Centrală", en: "Central Kurdish" },
    co: { native: "corsu", ro: "Corsicană", en: "Corsican" },
    cs: { native: "čeština", ro: "Cehă", en: "Czech" },
    cu: { native: "Church Slavic", ro: "Slavonă", en: "Church Slavic" },
    cy: { native: "Cymraeg", ro: "Galeză", en: "Welsh" },
    da: { native: "dansk", ro: "Daneză", en: "Danish" },
    dav: { native: "Kitaita", ro: "Taita", en: "Taita" },
    de: { native: "Deutsch", ro: "Germană", en: "German" },
    dje: { native: "Zarmaciine", ro: "Zarma", en: "Zarma" },
    doi: { native: "डोगरी", ro: "Dogri", en: "Dogri" },
    dsb: { native: "dolnoserbšćina", ro: "Sorabă De Jos", en: "Lower Sorbian" },
    dua: { native: "duálá", ro: "Duala", en: "Duala" },
    dv: { native: "Divehi", ro: "Divehi", en: "Divehi" },
    dyo: { native: "joola", ro: "Jola-Fonyi", en: "Jola-Fonyi" },
    dz: { native: "རྫོང་ཁ", ro: "Dzongkha", en: "Dzongkha" },
    ebu: { native: "Kĩembu", ro: "Embu", en: "Embu" },
    ee: { native: "Eʋegbe", ro: "Ewe", en: "Ewe" },
    el: { native: "Ελληνικά", ro: "Greacă", en: "Greek" },
    en: { native: "English", ro: "Engleză", en: "English" },
    eo: { native: "esperanto", ro: "Esperanto", en: "Esperanto" },
    es: { native: "español", ro: "Spaniolă", en: "Spanish" },
    et: { native: "eesti", ro: "Estonă", en: "Estonian" },
    eu: { native: "euskara", ro: "Bască", en: "Basque" },
    ewo: { native: "ewondo", ro: "Ewondo", en: "Ewondo" },
    fa: { native: "فارسی", ro: "Persană", en: "Persian" },
    ff: { native: "Pulaar", ro: "Fulah", en: "Fulah" },
    fi: { native: "suomi", ro: "Finlandeză", en: "Finnish" },
    fil: { native: "Filipino", ro: "Filipineză", en: "Filipino" },
    fo: { native: "føroyskt", ro: "Feroeză", en: "Faroese" },
    fr: { native: "français", ro: "Franceză", en: "French" },
    fur: { native: "furlan", ro: "Friulană", en: "Friulian" },
    fy: { native: "Frysk", ro: "Frizonă Occidentală", en: "Western Frisian" },
    ga: { native: "Gaeilge", ro: "Irlandeză", en: "Irish" },
    gd: { native: "Gàidhlig", ro: "Gaelică Scoțiană", en: "Scottish Gaelic" },
    gl: { native: "galego", ro: "Galiciană", en: "Galician" },
    gn: { native: "avañe’ẽ", ro: "Guarani", en: "Guarani" },
    gsw: { native: "Schwiizertüütsch", ro: "Germană (Elveția)", en: "Swiss German" },
    gu: { native: "ગુજરાતી", ro: "Gujarati", en: "Gujarati" },
    guz: { native: "Ekegusii", ro: "Gusii", en: "Gusii" },
    gv: { native: "Gaelg", ro: "Manx", en: "Manx" },
    ha: { native: "Hausa", ro: "Hausa", en: "Hausa" },
    haw: { native: "ʻŌlelo Hawaiʻi", ro: "Hawaiiană", en: "Hawaiian" },
    he: { native: "עברית", ro: "Ebraică", en: "Hebrew" },
    hi: { native: "हिन्दी", ro: "Hindi", en: "Hindi" },
    hr: { native: "hrvatski", ro: "Croată", en: "Croatian" },
    hsb: { native: "hornjoserbšćina", ro: "Sorabă De Sus", en: "Upper Sorbian" },
    hu: { native: "magyar", ro: "Maghiară", en: "Hungarian" },
    hy: { native: "հայերեն", ro: "Armeană", en: "Armenian" },
    ia: { native: "interlingua", ro: "Interlingua", en: "Interlingua" },
    ibb: { native: "Ibibio-Efik", ro: "Ibibio", en: "Ibibio" },
    id: { native: "Indonesia", ro: "Indoneziană", en: "Indonesian" },
    ig: { native: "Igbo", ro: "Igbo", en: "Igbo" },
    ii: { native: "ꆈꌠꉙ", ro: "Yi Din Sichuan", en: "Yi" },
    is: { native: "íslenska", ro: "Islandeză", en: "Icelandic" },
    it: { native: "italiano", ro: "Italiană", en: "Italian" },
    iu: { native: "Inuktitut", ro: "Inuktitut", en: "Inuktitut" },
    ja: { native: "日本語", ro: "Japoneză", en: "Japanese" },
    jgo: { native: "Ndaꞌa", ro: "Ngomba", en: "Ngomba" },
    jmc: { native: "Kimachame", ro: "Machame", en: "Machame" },
    jv: { native: "Jawa", ro: "Javaneză", en: "Javanese" },
    ka: { native: "ქართული", ro: "Georgiană", en: "Georgian" },
    kab: { native: "Taqbaylit", ro: "Kabyle", en: "Kabyle" },
    kam: { native: "Kikamba", ro: "Kamba", en: "Kamba" },
    kde: { native: "Chimakonde", ro: "Makonde", en: "Makonde" },
    kea: { native: "kabuverdianu", ro: "Kabuverdianu", en: "Kabuverdianu" },
    khq: { native: "Koyra ciini", ro: "Koyra Chiini", en: "Koyra Chiini" },
    ki: { native: "Gikuyu", ro: "Kikuyu", en: "Kikuyu" },
    kk: { native: "қазақ тілі", ro: "Kazahă", en: "Kazakh" },
    kkj: { native: "kakɔ", ro: "Kako", en: "Kako" },
    kl: { native: "kalaallisut", ro: "Kalaallisut", en: "Kalaallisut" },
    kln: { native: "Kalenjin", ro: "Kalenjin", en: "Kalenjin" },
    km: { native: "ខ្មែរ", ro: "Khmeră", en: "Khmer" },
    kn: { native: "ಕನ್ನಡ", ro: "Kannada", en: "Kannada" },
    ko: { native: "한국어", ro: "Coreeană", en: "Korean" },
    kok: { native: "कोंकणी", ro: "Konkani", en: "Konkani" },
    kr: { native: "Kanuri", ro: "Kanuri", en: "Kanuri" },
    ks: { native: "کٲشُر", ro: "Cașmiră", en: "Kashmiri" },
    ksb: { native: "Kishambaa", ro: "Shambala", en: "Shambala" },
    ksf: { native: "rikpa", ro: "Bafia", en: "Bafia" },
    ksh: { native: "Kölsch", ro: "Kölsch", en: "Colognian" },
    kw: { native: "kernewek", ro: "Cornică", en: "Cornish" },
    ky: { native: "кыргызча", ro: "Kârgâză", en: "Kyrgyz" },
    la: { native: "Latin", ro: "Latină", en: "Latin" },
    lag: { native: "Kɨlaangi", ro: "Langi", en: "Langi" },
    lb: { native: "Lëtzebuergesch", ro: "Luxemburgheză", en: "Luxembourgish" },
    lg: { native: "Luganda", ro: "Ganda", en: "Ganda" },
    lkt: { native: "Lakȟólʼiyapi", ro: "Lakota", en: "Lakota" },
    ln: { native: "lingála", ro: "Lingala", en: "Lingala" },
    lo: { native: "ລາວ", ro: "Laoțiană", en: "Lao" },
    lrc: { native: "لۊری شومالی", ro: "Luri De Nord", en: "Northern Luri" },
    lt: { native: "lietuvių", ro: "Lituaniană", en: "Lithuanian" },
    lu: { native: "Tshiluba", ro: "Luba-Katanga", en: "Luba-Katanga" },
    luo: { native: "Dholuo", ro: "Luo", en: "Luo" },
    luy: { native: "Luluhia", ro: "Luyia", en: "Luyia" },
    lv: { native: "latviešu", ro: "Letonă", en: "Latvian" },
    mai: { native: "मैथिली", ro: "Maithili", en: "Maithili" },
    mas: { native: "Maa", ro: "Masai", en: "Masai" },
    mer: { native: "Kĩmĩrũ", ro: "Meru", en: "Meru" },
    mfe: { native: "kreol morisien", ro: "Morisyen", en: "Morisyen" },
    mg: { native: "Malagasy", ro: "Malgașă", en: "Malagasy" },
    mgh: { native: "Makua", ro: "Makhuwa-Meetto", en: "Makhuwa-Meetto" },
    mgo: { native: "metaʼ", ro: "Meta’", en: "Metaʼ" },
    mi: { native: "te reo Māori", ro: "Maori", en: "Maori" },
    mk: { native: "македонски", ro: "Macedoneană", en: "Macedonian" },
    ml: { native: "മലയാളം", ro: "Malayalam", en: "Malayalam" },
    mn: { native: "монгол", ro: "Mongolă", en: "Mongolian" },
    mni: { native: "মৈতৈলোন্", ro: "Manipuri", en: "Manipuri" },
    moh: { native: "Kanienʼkéha", ro: "Mohawk", en: "Mohawk" },
    mr: { native: "मराठी", ro: "Marathi", en: "Marathi" },
    ms: { native: "Melayu", ro: "Malaeză", en: "Malay" },
    mt: { native: "Malti", ro: "Malteză", en: "Maltese" },
    mua: { native: "MUNDAŊ", ro: "Mundang", en: "Mundang" },
    my: { native: "မြန်မာ", ro: "Birmană", en: "Burmese" },
    mzn: { native: "مازرونی", ro: "Mazanderani", en: "Mazanderani" },
    naq: { native: "Khoekhoegowab", ro: "Nama", en: "Nama" },
    nb: { native: "norsk bokmål", ro: "Norvegiană Bokmål", en: "Norwegian Bokmål" },
    nd: { native: "isiNdebele", ro: "Ndebele De Nord", en: "North Ndebele" },
    nds: { native: "Low German", ro: "Germana De Jos", en: "Low German" },
    ne: { native: "नेपाली", ro: "Nepaleză", en: "Nepali" },
    nl: { native: "Nederlands", ro: "Neerlandeză", en: "Dutch" },
    nmg: { native: "Kwasio", ro: "Kwasio", en: "Kwasio" },
    nn: { native: "norsk nynorsk", ro: "Norvegiană Nynorsk", en: "Norwegian Nynorsk" },
    nnh: { native: "Shwóŋò ngiembɔɔn", ro: "Ngiemboon", en: "Ngiemboon" },
    nqo: { native: "ߒߞߏ", ro: "N’Ko", en: "N’Ko" },
    nr: { native: "South Ndebele", ro: "Ndebele De Sud", en: "South Ndebele" },
    nso: { native: "Sesotho sa Leboa", ro: "Sotho De Nord", en: "Sesotho Sa Leboa" },
    nus: { native: "Thok Nath", ro: "Nuer", en: "Nuer" },
    nyn: { native: "Runyankore", ro: "Nyankole", en: "Nyankole" },
    oc: { native: "Occitan", ro: "Occitană", en: "Occitan" },
    om: { native: "Oromoo", ro: "Oromo", en: "Oromo" },
    or: { native: "ଓଡ଼ିଆ", ro: "Odia", en: "Odia" },
    os: { native: "ирон", ro: "Osetă", en: "Ossetic" },
    pa: { native: "ਪੰਜਾਬੀ", ro: "Punjabi", en: "Punjabi" },
    pap: { native: "Papiamentu", ro: "Papiamento", en: "Papiamento" },
    pcm: { native: "Naijíriá Píjin", ro: "Pidgin Nigerian", en: "Nigerian Pidgin" },
    pl: { native: "polski", ro: "Poloneză", en: "Polish" },
    prg: { native: "prūsiskan", ro: "Prusacă", en: "Prussian" },
    ps: { native: "پښتو", ro: "Paștună", en: "Pashto" },
    pt: { native: "português", ro: "Portugheză", en: "Portuguese" },
    qu: { native: "Runasimi", ro: "Quechua", en: "Quechua" },
    quc: { native: "Kʼicheʼ", ro: "Quiché", en: "Kʼicheʼ" },
    rm: { native: "rumantsch", ro: "Romanșă", en: "Romansh" },
    rn: { native: "Ikirundi", ro: "Kirundi", en: "Rundi" },
    ro: { native: "română", ro: "Română", en: "Romanian" },
    rof: { native: "Kihorombo", ro: "Rombo", en: "Rombo" },
    ru: { native: "русский", ro: "Rusă", en: "Russian" },
    rw: { native: "Kinyarwanda", ro: "Kinyarwanda", en: "Kinyarwanda" },
    rwk: { native: "Kiruwa", ro: "Rwa", en: "Rwa" },
    sa: { native: "संस्कृत भाषा", ro: "Sanscrită", en: "Sanskrit" },
    sah: { native: "саха тыла", ro: "Sakha", en: "Sakha" },
    saq: { native: "Kisampur", ro: "Samburu", en: "Samburu" },
    sat: { native: "ᱥᱟᱱᱛᱟᱲᱤ", ro: "Santali", en: "Santali" },
    sbp: { native: "Ishisangu", ro: "Sangu", en: "Sangu" },
    sd: { native: "سنڌي", ro: "Sindhi", en: "Sindhi" },
    se: { native: "davvisámegiella", ro: "Sami De Nord", en: "Northern Sami" },
    seh: { native: "sena", ro: "Sena", en: "Sena" },
    ses: { native: "Koyraboro senni", ro: "Koyraboro Senni", en: "Koyraboro Senni" },
    sg: { native: "Sängö", ro: "Sango", en: "Sango" },
    shi: { native: "ⵜⴰⵛⵍⵃⵉⵜ", ro: "Tachelhit", en: "Tachelhit" },
    si: { native: "සිංහල", ro: "Singhaleză", en: "Sinhala" },
    sk: { native: "slovenčina", ro: "Slovacă", en: "Slovak" },
    sl: { native: "slovenščina", ro: "Slovenă", en: "Slovenian" },
    sma: { native: "Åarjelsaemien gïele", ro: "Sami De Sud", en: "Southern Sami" },
    smj: { native: "julevsámegiella", ro: "Sami Lule", en: "Lule Sami" },
    smn: { native: "anarâškielâ", ro: "Sami Inari", en: "Inari Sami" },
    sms: { native: "Skolt Sami", ro: "Sami Skolt", en: "Skolt Sami" },
    sn: { native: "chiShona", ro: "Shona", en: "Shona" },
    so: { native: "Soomaali", ro: "Somaleză", en: "Somali" },
    sq: { native: "shqip", ro: "Albaneză", en: "Albanian" },
    sr: { native: "српски", ro: "Sârbă", en: "Serbian" },
    ss: { native: "siSwati", ro: "Swati", en: "Siswati" },
    ssy: { native: "Saho", ro: "Saho", en: "Saho" },
    st: { native: "Sesotho", ro: "Sesotho", en: "Sesotho" },
    su: { native: "Basa Sunda", ro: "Sundaneză", en: "Sundanese" },
    sv: { native: "svenska", ro: "Suedeză", en: "Swedish" },
    sw: { native: "Kiswahili", ro: "Swahili", en: "Kiswahili" },
    syr: { native: "Syriac", ro: "Siriacă", en: "Syriac" },
    ta: { native: "தமிழ்", ro: "Tamilă", en: "Tamil" },
    te: { native: "తెలుగు", ro: "Telugu", en: "Telugu" },
    teo: { native: "Kiteso", ro: "Teso", en: "Teso" },
    tg: { native: "тоҷикӣ", ro: "Tadjică", en: "Tajik" },
    th: { native: "ไทย", ro: "Thailandeză", en: "Thai" },
    ti: { native: "ትግር", ro: "Tigrină", en: "Tigrinya" },
    tig: { native: "Tigre", ro: "Tigre", en: "Tigre" },
    tk: { native: "türkmen dili", ro: "Turkmenă", en: "Turkmen" },
    tn: { native: "Setswana", ro: "Setswana", en: "Setswana" },
    to: { native: "lea fakatonga", ro: "Tongană", en: "Tongan" },
    tr: { native: "Türkçe", ro: "Turcă", en: "Turkish" },
    ts: { native: "Xitsonga", ro: "Tsonga", en: "Xitsonga" },
    tt: { native: "татар", ro: "Tătară", en: "Tatar" },
    twq: { native: "Tasawaq senni", ro: "Tasawaq", en: "Tasawaq" },
    tzm: { native: "Tamaziɣt n laṭlaṣ", ro: "Tamazight Din Atlasul Central", en: "Central Atlas Tamazight" },
    ug: { native: "ئۇيغۇرچە", ro: "Uigură", en: "Uyghur" },
    uk: { native: "українська", ro: "Ucraineană", en: "Ukrainian" },
    ur: { native: "اردو", ro: "Urdu", en: "Urdu" },
    uz: { native: "o‘zbek", ro: "Uzbecă", en: "Uzbek" },
    vai: { native: "ꕙꔤ", ro: "Vai", en: "Vai" },
    ve: { native: "Venda", ro: "Venda", en: "Venda" },
    vi: { native: "Tiếng Việt", ro: "Vietnameză", en: "Vietnamese" },
    vo: { native: "Volapük", ro: "Volapuk", en: "Volapük" },
    vun: { native: "Kyivunjo", ro: "Vunjo", en: "Vunjo" },
    wae: { native: "Walser", ro: "Walser", en: "Walser" },
    wal: { native: "Wolaytta", ro: "Wolaita", en: "Wolaytta" },
    wo: { native: "Wolof", ro: "Wolof", en: "Wolof" },
    xh: { native: "isiXhosa", ro: "Xhosa", en: "Isixhosa" },
    xog: { native: "Olusoga", ro: "Soga", en: "Soga" },
    yav: { native: "nuasue", ro: "Yangben", en: "Yangben" },
    yi: { native: "ייִדיש", ro: "Idiș", en: "Yiddish" },
    yo: { native: "Èdè Yorùbá", ro: "Yoruba", en: "Yoruba" },
    zgh: { native: "ⵜⴰⵎⴰⵣⵉⵖⵜ", ro: "Tamazight Standard Marocană", en: "Standard Moroccan Tamazight" },
    zh: { native: "中文", ro: "Chineză", en: "Chinese" },
    zu: { native: "isiZulu", ro: "Zulu", en: "Isizulu" }
}

export default languages