import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"

// lib
import { CovidTestsUrl, LabTestsUrl } from "config/redirects"
import routes from "config/routes"

// components
import ImageWIthPreview from "components/ImageWIthPreview/ImageWIthPreview"
import TopBar from "components/TopBar/TopBar"
import Footer from "components/Footer/Footer"
import DoctorPage from "pages/DoctorPage/DoctoPage"
import Modal from "components/Modal/Modal"
import StoreLinks from "components/StoreLinks/StoreLinks"
import PasswordResetCard from "components/PasswordResetCard/PasswordResetCard"

// assets
import AgathaApp1 from "assets/app/Screenshot1.png"
import AgathaApp2 from "assets/app/Screenshot2.png"
import AgathaApp3 from "assets/app/Screenshot3.png"
import Bubbles1 from "assets/app/Bubbles1.png"
import Bubbles2 from "assets/app/Bubbles2.png"
import Bubbles3 from "assets/app/Bubbles3.png"
import "pages/HomePage/HomePage.scss"

export enum EHomePageDialog {
    None,
    DoctorPage,
    PasswordResetSuccess,
    PasswordResetFailed
}

interface IProps {
    dialog?: EHomePageDialog
}

const HomePage: FunctionComponent<IProps> = ({ dialog = EHomePageDialog.None }) => {
    const { t } = useTranslation()
    const history = useHistory()

    return <>
        <TopBar/>

        {/*some description*/}
        <div className="section">
            <div className="container">
                {/* app */}
                <div className="columns has-text-left home-info-row mb-6">
                    <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
                        <div id="our-app" className="box-text">
                            <h3 className="box-label">
                                { t("home.entries.ourApp.title") }
                            </h3>

                            <h2 className="box-point">
                                { t("home.entries.ourApp.subtitle") }
                            </h2>

                            <p className="box-desc">
                                { t("home.entries.ourApp.description.p1") }
                            </p>

                            <br/>

                            <p className="box-desc">
                                { t("home.entries.ourApp.description.p2") }
                            </p>

                            <div className="box-app-store-links">
                                <StoreLinks />
                            </div>
                        </div>
                    </div>
                    <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
                        <ImageWIthPreview image={ AgathaApp1 } backgroundImage={ Bubbles1 } alt="Agatha App Screenshot" maxHeightPX={ 600 } />
                    </div>
                </div>

                {/* doctors */}
                <div className="columns has-text-left home-info-row mb-6">
                    <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
                        <figure className="image">
                            <ImageWIthPreview image={ AgathaApp2 } backgroundImage={ Bubbles2 } alt="Agatha App Screenshot" maxHeightPX={ 600 } />
                        </figure>
                    </div>
                    <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
                        <div id="doctors" className="info-box">
                            <h3 className="box-label">
                                { t("home.entries.doctors.title") }
                            </h3>

                            <h2 className="box-point">
                                { t("home.entries.doctors.subtitle") }
                            </h2>

                            <p className="box-desc">
                                { t("home.entries.doctors.description.p1") }
                            </p>

                            <br/>

                            <p className="box-desc">
                                { t("home.entries.doctors.description.p2") }
                            </p>

                            <br/>

                            <p className="box-desc">
                                { t("home.entries.doctors.description.p3") }
                            </p>
                        </div>
                    </div>
                </div>

                {/* lab tests */}
                <div className="columns has-text-left home-info-row mb-6">
                    <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
                        <div id="lab-tests" className="info-box">
                            <h3 className="box-label">
                                { t("home.entries.labTests.title") }
                            </h3>

                            <h2 className="box-point">
                                { t("home.entries.labTests.subtitle") }
                            </h2>

                            <p className="box-desc">
                                { t("home.entries.labTests.description.p1") }
                            </p>

                            <br/>

                            <p className="box-desc">
                                { t("home.entries.labTests.description.p2") }
                            </p>

                            <br/>

                            <p className="box-desc">
                                { t("home.entries.labTests.description.p3") }
                            </p>

                            <div className="is-flex is-justify-content-space-around box-tests-links">
                                <a className="box-test-link" href={ CovidTestsUrl } target="_blank">
                                    { t("home.entries.labTests.links.covidTests") }
                                </a>
                                <a className="box-test-link" href={ LabTestsUrl } target="_blank">
                                    { t("home.entries.labTests.links.labTests") }
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="column is-6 is-flex is-justify-content-center is-align-items-center">
                        <figure className="image">
                            <ImageWIthPreview image={ AgathaApp3 } backgroundImage={ Bubbles3 } alt="Agatha App Screenshot" maxHeightPX={ 600 } />
                        </figure>
                    </div>
                </div>

                {/*some other description or maybe a caroussel with some pics*/}
                <div className="columns has-text-centered home-info-row">
                    <div className="column is-flex is-justify-content-center is-align-items-center">
                        <div id="contact-us" className="info-box">
                            <div className="column">
                                <h3 className="box-label" style={ { marginBottom: "40px" } }>
                                    { t("home.entries.contactUs.title") }
                                </h3>
                                {/*<h2 className="box-point">Talk directly and bond with your doctor.</h2>*/}
                                <p className="box-desc">
                                    { t("home.entries.contactUs.description.p1") }
                                </p>

                                <br/>

                                <a className="emailLink" href="mailto: office@agatha.health">office@agatha.health</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/* redirectedFrom modal */}
        {
            dialog != EHomePageDialog.None
            && <>
                <Modal
                    showCloseButton={ true }
                    onClose={ () => history.push(routes.home) }
                >
                    {
                        (() => {
                            switch (dialog) {
                                case EHomePageDialog.DoctorPage:
                                    return <DoctorPage />

                                case EHomePageDialog.PasswordResetSuccess:
                                    return <PasswordResetCard type="success" />

                                case EHomePageDialog.PasswordResetFailed:
                                    return <PasswordResetCard type="failed" />
                            }
                        })()
                    }
                </Modal>
            </>
        }

        <Footer />
    </>
}

export default HomePage