import axios from "axios"

interface IPostDoctorJoinRequestRequest {
    givenName: string
    familyName: string
    email: string
    phoneNumber: string
    specialty: string
    spokenLanguages: string
    firstYearOfMedicalExperience: string
}

type IPostDoctorJoinRequestParams = {
    request: IPostDoctorJoinRequestRequest
    language: string
    from?: string
}

export const postDoctorJoinRequest = async ({ request, from, language }: IPostDoctorJoinRequestParams): Promise<void> => {
    await axios.post('/v1/join', request, {
        params: {
            language: language,
            from: from
            // ...(from && { from: from }),
        }
    });
}