// export const convertYouTubeUrlToId = (url: string) => {
//     // Just the regex. Output is in [1].
//     const exp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/
//     const match = url.match(exp)
//
//     if (match && match.length >= 2)
//         return match[1]
//
//     return ""
// }

export const getYouTubeVideoIdFromUrl = (url: string): string => {
    const regex = /(https?:\/\/)?((www\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i
    const match = url.match(regex)
    return match ? match[7] : ""
}

export const getYouTubeThumbnail = (videoId: string) => {
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
}