import axios from "axios"

interface IGetRandomCampaignCodeResponseJson {
    campaignCode: string | null
}

type IGetRandomCampaignCodeResponse = string | null

export const getRandomCampaignCode = async (pageIdentifier: string): Promise<IGetRandomCampaignCodeResponse> => {
    const response = await axios.get<IGetRandomCampaignCodeResponseJson>(`/v1/random-campaign-code/${pageIdentifier}`)
    return response.data.campaignCode
}