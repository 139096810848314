import axiosRetry from "axios-retry"
import axios, { AxiosError } from "axios"

export const setUpAxios = () => {
    // set up axios-retry
    // https://github.com/softonic/axios-retry/issues/87
    const retryDelay = (retryNumber = 0) => {
        const seconds = Math.pow(2, retryNumber) * 1000;
        const randomMs = 1000 * Math.random();
        return seconds + randomMs;
    };

    axiosRetry(axios, {
        retries: 2,
        retryDelay,
        // retry on Network Error & 5xx responses
        retryCondition: axiosRetry.isRetryableError,
    });

    axios.defaults.baseURL = process.env.REACT_APP_API_HOSTNAME!
}

export function isAxiosError(error: any): error is AxiosError {
    return ('isAxiosError' in error && error.isAxiosError)
}

export function handleAxiosError(error: Error, responseErrorCallback: (e: AxiosError) => void, requestErrorCallback: (e: AxiosError) => void, otherErrorCallback: (e: any) => void): void {
    if (isAxiosError(error)) {
        if (error.response)
            return responseErrorCallback(error)

        if (error.request)
            return requestErrorCallback(error)
    }

    return otherErrorCallback(error)
}