//assets
import "App.scss"
import { Landscape, Portrait } from "components/Responsive/Responsive"

// lib
import routes from "config/routes"

// components
import CampaignCode from "pages/CampaignCode/CampaignCode"
import DoctorPage from "pages/DoctorPage/DoctoPage"
import GdprPage from "pages/GdprPage/GdprPage"
import HomePage, { EHomePageDialog } from "pages/HomePage/HomePage"
import PrivacyPolicyPage from "pages/PrivacyPolicyPage/PrivacyPolicyPage"
import TermsAndConditionsPage from "pages/TermsAndConditionsPage/TermsAndConditionsPage"
import { FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import CookieConsentBar from "./components/CookieConsentBar/CookieConsentBar"
import DeleteAccountPage from "./pages/DeleteAccount/DeleteAccountPage"
import JoinUsPage from "./pages/JoinUsPage/JoinUsPage"

const App: FunctionComponent = () => {
    return <>
        <div className="app">
            <Switch>
                {/*Home*/}
                <Route exact path={ routes.home } component={ HomePage } />
                <Route path={ routes.doctors } render={ () => <>
                    <Portrait>
                        <DoctorPage />
                    </Portrait>

                    <Landscape>
                        <HomePage dialog={ EHomePageDialog.DoctorPage} />
                    </Landscape>
                </>
                }
                />
                <Route path={ routes.campaignCode } component={ CampaignCode } />
                <Route path={ routes.joinUs } component={ JoinUsPage } />
                <Route exact path={ routes.passwordResetSuccess } render={ () => <HomePage dialog={ EHomePageDialog.PasswordResetSuccess } /> } />
                <Route exact path={ routes.passwordResetFailed } render={ () => <HomePage dialog={ EHomePageDialog.PasswordResetFailed } /> } />


                <Route exact path={ routes.deleteAccount } component={ DeleteAccountPage } />
                <Route exact path={ routes.gdpr } component={ GdprPage } />
                <Route exact path={ routes.privacyPolicy } component={ PrivacyPolicyPage } />
                <Route exact path={ routes.termsAndConditions } component={ TermsAndConditionsPage } />

                {/* any other url */}
                <Route>
                    <Redirect to={ routes.home } />
                </Route>
            </Switch>

            <CookieConsentBar />
        </div>
    </>
}

export default App