import DoctorMediaCarousel from "components/Doctor/DoctorMediaCarousel/DoctorMediaCarousel"
import { FunctionComponent, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"
import { useHistory, useParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import Icon from "@mdi/react"
import {
    mdiAccountVoice,
    mdiCalendarClock, mdiFormatQuoteClose, mdiFormatQuoteOpen,
    mdiHospitalBuilding,
    mdiMedal,
    mdiMedicalBag,
    mdiPhoneCheck, mdiSchool,
    mdiSeal,
    mdiWeatherNight
} from "@mdi/js"
import { DateTime } from "luxon"

// components
import { Landscape, Portrait } from "components/Responsive/Responsive"
import Stars from "components/Stars/Stars"
import TopBar from "components/TopBar/TopBar"
import Footer from "components/Footer/Footer"
import StoreLinks from "components/StoreLinks/StoreLinks"

// lib
import Doctor from "data/Doctor"
import DoctorPresentationDetails from "data/DoctorPresentationDetails"
import DoctorSpecialty from "data/DoctorSpecialty"
import DoctorCareerPointType from "enums/DoctorCareerPointType"
import api from "service/api"
import routes from "config/routes"
import languages from "data/Languages"

// assets
import "pages/DoctorPage/DoctorPage.scss"

interface IParams {
    doctorId: string
}

const DoctorPage: FunctionComponent = () => {
    const { t, i18n } = useTranslation("translation")
    const { doctorId } = useParams<IParams>()
    const history = useHistory()

    const [ isLoading, setIsLoading ] = useState<boolean>(true)
    const [ specialties, setSpecialties ] = useState<DoctorSpecialty[]>([])
    const [ doctor, setDoctor ] = useState<Doctor>()
    const [ presentation, setPresentation ] = useState<DoctorPresentationDetails>()

    useEffect(() => {
        const fetch = async () => {
            try {
                const specialties = await api.getDoctorSpecialties()
                const presentationDetails = await api.getDoctorPresentationV2(doctorId, i18n.resolvedLanguage)

                setSpecialties(specialties)
                setDoctor(presentationDetails.doctor)
                setPresentation(presentationDetails.presentation)
                setIsLoading(false)
            }
            catch (e) {
                history.replace(routes.home)
            }
        }

        fetch()
    }, [])

    const spokenLanguagesString = () => {
        const languagesString = doctor!.spokenLanguages
            .map(it => languages[it][i18n.resolvedLanguage] ?? languages[it].en )
            .reduce(
                (accumulator, currentValue, currentIndex) => `${accumulator}${currentIndex == 0 ? "" : ", "}${currentValue}`,
                ""
            )
        return t("doctors.atAGlance.speaksLanguages", { languages: languagesString })
    }

    const yearsOfExperienceString = () => {
        const start = doctor!.firstYearOfMedicalExperience
        const end = DateTime.now()
        const years = end.diff(start, 'years').years.toFixed(0)

        return t("doctors.atAGlance.yearsOfExperience", { yearsOfExperience: years.toString() })
    }

    const scheduleString = () => {
        const start = doctor!.scheduleBegin.toLocal().toFormat("HH:mm").toString()
        const end = doctor!.scheduleEnd.toLocal().toFormat("HH:mm").toString()

        return t("doctors.atAGlance.schedule", { start: start, end: end })
    }

    const numberToCurrencyString = (number: number) => {
        const numberFormat = Intl.NumberFormat(
            i18n.resolvedLanguage,
            {
                style: "currency",
                currency: "EUR",
                maximumFractionDigits: 2,
            }
        )
        return numberFormat.format(number)
    }

    const availabilityString = () => {
        // scheduleStart and scheduleEnd have some random date,
        // we only need to check the time
        const start = DateTime.now().toUTC().set({ hour: doctor!.scheduleBegin.hour, minute: doctor!.scheduleBegin.minute })
        const end = DateTime.now().toUTC().set({ hour: doctor!.scheduleEnd.hour, minute: doctor!.scheduleEnd.minute })
        const now = DateTime.now().toUTC()

        const inSchedule = start <= now && end > now
        if (inSchedule)
            return t("doctors.availability.inSchedule", {
                scheduleTime: end.toFormat("HH:mm").toString(),
                rate: numberToCurrencyString(doctor!.ratePerMinuteInUnit)
            })
        else if (doctor!.acceptsOutsideOfSchedule)
            return <Trans
                i18nKey={ "doctors.availability.outsideOfSchedule" }
                values={{
                    timeBegin: start.toFormat("HH:mm").toString(),
                    timeEnd: end.toFormat("HH:mm").toString()
                }}
                components={{ 1: <br/> }}
            />
        else
            return <Trans
                i18nKey={ "doctors.availability.unavailable" }
                values={{
                    timeBegin: start.toFormat("HH:mm").toString(),
                    timeEnd: end.toFormat("HH:mm").toString()
                }}
                components={{ 1: <br/> }}
            />
    }

    const renderDoctorPageContent = () => {
        return <>
            <div className="columns is-centered">
                <div className="column has-text-centered">
                    {/*profile picture*/}
                    <figure className="image">
                        <img
                            className="profile-picture is-rounded"
                            src={ doctor!.profilePicture }
                            alt="doctor-profile-picture"
                        />
                    </figure>

                    {/*name*/}
                    <p className="title my-4"> { t("doctors.nameTitle", { fullName: `${doctor!.givenName} ${doctor!.familyName}` }) } </p>

                    {/*score*/}
                    <p className="score mb-4">
                        <Stars score={ doctor!.score } />
                    </p>

                    {/*availability*/}
                    <p className="mb-4"> { availabilityString() } </p>

                    {/*download now button*/}
                    <StoreLinks />

                    <br/>

                    {
                        doctor!.quote
                        && (
                            <div className="quote columns is-vecentered">
                                <div className="column is-narrow is-flex is-align-items-start is-justify-start">
                                    <Icon path={ mdiFormatQuoteOpen } size={ 1.25 }/>
                                </div>
                                <div className="column">
                                    { doctor!.quote[i18n.resolvedLanguage] ?? doctor!.quote["en"] }
                                </div>
                                <div className="column is-narrow has-text-left is-flex is-justify-content-end is-align-items-end">
                                    <Icon path={ mdiFormatQuoteClose } size={ 1.25 }/>
                                </div>
                            </div>
                        )
                    }

                    {/*media content*/}
                    {
                        presentation!.mediaContent.length > 0
                        && <>
                            <div className="has-text-left">
                                <p className="title mb-2"> { t("doctors.media.title") } </p>

                                <p className="mb-1">
                                    { t("doctors.media.subtitle", { givenName: doctor!.givenName }) }
                                </p>

                                <DoctorMediaCarousel media={ presentation?.mediaContent! } />
                            </div>
                        </>
                    }

                    <br/>

                    <div className="has-text-left">
                        <p className="title mb-2"> { t("doctors.atAGlance.title") } </p>

                        <p className="mb-1">
                            { t("doctors.atAGlance.subtitle", { familyName: doctor!.givenName }) }
                        </p>

                        {/*at a glance*/}
                        <div className="at-a-glance card mb-5">
                            <div className="card-content">
                                <div className="content">

                                    {/*specialty*/}
                                    <div className="mb-2">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <Icon path={ mdiMedicalBag } size={ 1.5 } />
                                            </span>
                                            <span>
                                                {
                                                    (() => {
                                                        const entry = specialties.find(it => it.id == doctor!.primarySpecialty)
                                                        if (!entry)
                                                            return ""

                                                        return entry.translations[i18n.resolvedLanguage] ?? entry.translations["en"]
                                                    })()
                                                }
                                            </span>
                                        </span>
                                    </div>

                                    {/*spoken languages*/}
                                    <div className="mb-2">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <Icon path={ mdiAccountVoice } size={ 1.5 } />
                                            </span>
                                            <span>
                                                { spokenLanguagesString() }
                                            </span>
                                        </span>
                                    </div>

                                    {/*online consultations over count approximation*/}
                                    <div className="mb-2">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <Icon path={ mdiPhoneCheck } size={ 1.5 } />
                                            </span>
                                            <span>
                                                { t("doctors.atAGlance.overApproxOnlineConsultations", { approx: doctor!.onlineConsultationsOverCountApproximation }) }
                                            </span>
                                        </span>
                                    </div>

                                    {/*medical experience*/}
                                    <div className="mb-2">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <Icon path={ mdiSeal } size={ 1.5 } />
                                            </span>
                                            <span>
                                                { yearsOfExperienceString() }
                                            </span>
                                        </span>
                                    </div>

                                    {/*schedule*/}
                                    <div className="mb-2">
                                        <span className="icon-text">
                                            <span className="icon">
                                                <Icon path={ mdiCalendarClock } size={ 1.5 } />
                                            </span>
                                            <span>
                                                { scheduleString() }
                                            </span>
                                        </span>
                                    </div>

                                    {/*out of schedule*/}
                                    {
                                        doctor!.outOfScheduleRatePerMinuteInUnit != 0
                                        && <>
                                            <div className="mb-0">
                                                <span className="icon-text">
                                                    <span className="icon">
                                                        <Icon path={ mdiWeatherNight } size={ 1.5 } />
                                                    </span>
                                                    { t("doctors.atAGlance.outOfSchedule", { rate: numberToCurrencyString(doctor!.outOfScheduleRatePerMinuteInUnit) }) }
                                                </span>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        <p className="title mb-2">
                            { t("doctors.about.title", { name: `${doctor!.familyName}`}) }
                        </p>

                        <p className="mb-1">
                            { t("doctors.about.subtitle", { name: `${doctor!.givenName} ${doctor!.familyName}`}) }
                        </p>

                        {/*description*/}
                        <div className="card">
                            <div className="card-content">
                                <div className="content">
                                    {/*<p className="title mb-2"> { t("doctors.descriptionTitle") } </p>*/}

                                    <p>
                                        <ReactMarkdown>
                                            { presentation!.description }
                                        </ReactMarkdown>
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/*career history*/}
                        <div className="career-history card mt-3 mb-2">
                            <div className="card-content">
                                <div className="content">
                                    <p className="title mb-2"> { t("doctors.careerHistoryTitle") } </p>

                                    {/*points*/}
                                    {
                                        presentation!.careerPoints.map((it, index) => {
                                            const icon = (() => {
                                                switch (it.type) {
                                                    case DoctorCareerPointType.Education:
                                                        return mdiSchool

                                                    case DoctorCareerPointType.Clinic:
                                                    case DoctorCareerPointType.Hospital:
                                                        return mdiHospitalBuilding

                                                    case DoctorCareerPointType.Award:
                                                        return mdiMedal

                                                    default:
                                                        return mdiSchool
                                                }
                                            })()

                                            return (
                                                <div key={ index } className="mb-2">
                                                    <span className="icon-text">
                                                        <span className="icon">
                                                            <Icon path={ icon } size={ 1.5 } />
                                                        </span>
                                                        <span> { it.description } </span>
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    if (isLoading)
        return <>Loading...</>

    return <>
        <Portrait>
            <TopBar logoOnly={ true }/>

            <div className="doctor container px-3 mb-2">
                { renderDoctorPageContent() }
            </div>

            <Footer />
        </Portrait>

        <Landscape>
            <div className="doctor card is-fullheight px-3">
                <div className="card-content">
                    <div className="content">
                        { renderDoctorPageContent() }
                    </div>
                </div>
            </div>
        </Landscape>
    </>
}

export default DoctorPage