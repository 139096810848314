import { FunctionComponent } from "react"

// lib
import DoctorMediaContent from "data/DoctorMediaContent"
import DoctorMediaContentType from "enums/DoctorMediaContentType"
import { getYouTubeVideoIdFromUrl, getYouTubeThumbnail } from "utils"

interface IProps {
    media: DoctorMediaContent
}

const DoctorMediaCard: FunctionComponent<IProps> = ({ media }) => {
    return <>
        <div className="card">
            <div className="card-image">
                <figure className="image is-16by9">
                    <img
                        src={ media.type === DoctorMediaContentType.Image
                            ? media.url
                            : getYouTubeThumbnail(getYouTubeVideoIdFromUrl(media.url))
                        }
                        alt={ media.url }
                    />
                </figure>
            </div>
        </div>
    </>
}

export default DoctorMediaCard