import { FunctionComponent, useState } from "react"
import { Parallax } from "react-parallax"

import "./ImageWithPreview.scss"
import Modal from "components/Modal/Modal"

interface Props {
    image: string
    backgroundImage: string
    alt: string
    maxHeightPX: number
}

const ImageWIthPreview: FunctionComponent<Props> = (props) => {
    const [ showZoom, setShowZoom ] = useState<boolean>(false)

    const toggleShowZoom = () => {
        setShowZoom(!showZoom)
    }

    return <>
        {/* @ts-ignore */}
        <Parallax
            strength={ 500 }
            style={ {
                overflow: "visible",
            } }
            renderLayer={
                (percentage) => {
                    let scale = percentage * 3

                    scale = scale > 2 ? 2 : scale

                    return <>
                        <img
                            src={ props.backgroundImage }
                            alt={ `${props.alt}-background` }
                            style={ {
                                position: "absolute",
                                maxHeight: `${props.maxHeightPX}px`,
                                left: "50%",
                                top: "50%",
                                transform: `translate(-50%, -50%) scale(${scale})`,
                                zIndex: -1
                            } }
                        />
                    </>
                }
            }
        >
            <div className="image-with-preview is-flex is-align-items-center" style={ { height: `${props.maxHeightPX}px` } }>
                <figure
                    className="image is-zoomable"
                    onClick={ () => { setShowZoom(true) } }
                >
                    <img
                        src={ props.image }
                        alt={ props.image }
                        style={ { maxHeight: `${props.maxHeightPX - 100}px` } }
                    />
                </figure>

                {/*modal*/}
                {
                    showZoom
                    && <>
                        <Modal 
                            showCloseButton={ true } 
                            onClose={ () => setShowZoom(false) }
                        >
                            <div className="is-fullheight is-flex is-justify-content-center is-align-items-center">
                                <p className="image">
                                    <img src={ props.image } alt={ props.alt }/>
                                </p>
                            </div>
                        </Modal>
                    </>
                }
            </div>
        </Parallax>
    </>
}

export default ImageWIthPreview