import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import Footer from "components/Footer/Footer"
import TopBar from "components/TopBar/TopBar"

import "./DeleteAccountPage.scss"

const DeleteAccountPage: FunctionComponent = () => {
    const { t } = useTranslation("translation")

    return <>
        <TopBar logoOnly={ true } />

        <div className="delete-account container px-3">
            <div className="columns is-centered">
                <div className="column has-text-centered">
                    <p className="title my-4">{ t("deleteAccount.title") }</p>

                    <br />

                    <p>{ t("deleteAccount.message") }
                        <a className="emailLink" href="mailto: office@agatha.health">office@agatha.health</a>
                    </p>
                </div>
            </div>
        </div>

        <Footer/>
    </>
}

export default DeleteAccountPage