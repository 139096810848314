import { FunctionComponent } from "react"

// assets
import { AppleAppStoreUrl, GooglePlayStoreUrl } from "config/redirects"
import GooglePlayBadge from "assets/google-play-badge.png"
import AppleStoreBadge from "assets/apple-store-badge.svg"

import "./StoreLinks.scss"
const StoreLinks: FunctionComponent = () => {
    return <>
        {/*  app store links */}
        {/*<div className="store-links">*/}
            <div className="store-links is-flex is-justify-content-space-around">
                <a href={ GooglePlayStoreUrl } target="_blank">
                    <img src={GooglePlayBadge} alt="Google play link" />
                </a>
                <a className="is-flex" href={ AppleAppStoreUrl } target="_blank">
                    <img src={ AppleStoreBadge } alt="App store link"/>
                </a>
            </div>
        {/*</div>*/}
    </>
}

export default StoreLinks