import DoctorCareerPointType from "enums/DoctorCareerPointType"

export interface IDoctorCareerPointJson {
    type: DoctorCareerPointType,
    description: string
}
class DoctorCareerPoint {
    constructor(
        public type: DoctorCareerPointType,
        public description: string
    ) {
    }
    
    public static fromJson(json: IDoctorCareerPointJson) {
        return new DoctorCareerPoint(
            json.type as DoctorCareerPointType,
            json.description
        )    
    } 
}

export default DoctorCareerPoint