import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

// components
import TopBar from "components/TopBar/TopBar"
import Footer from "components/Footer/Footer"

const TermsAndConditionsPage: FunctionComponent = () => {
    const { t } = useTranslation()

    return <>
        <TopBar logoOnly={ true }/>

        {/*some description*/}
        <div className="section">
            <div className="container">
                <div className="columns has-text-centered home-info-row">
                    <div className="column">
                        <h2 className="box-point">{ t("termsAndConditions.title") }</h2>
                        <p className="box-desc has-text-left has-styled-lists" dangerouslySetInnerHTML={ {
                            __html: t("termsAndConditions.content")
                        } }/>
                    </div>
                </div>
            </div>
        </div>

        <Footer/>
    </>
}

export default TermsAndConditionsPage