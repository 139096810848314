import { getDoctorPresentationV2 } from "service/endpoints/getDoctorPresentationV2"
import { getDoctorSpecialties } from "service/endpoints/getDoctorSpecialties"
import { getRandomCampaignCode } from "service/endpoints/getRandomCamapaignCode"

const api = {
    getDoctorPresentationV2: getDoctorPresentationV2,
    getDoctorSpecialties: getDoctorSpecialties,
    getRandomCampaignCode: getRandomCampaignCode
}

export default api



