import DoctorMediaContentType from "enums/DoctorMediaContentType"

export interface IDoctorMediaContentJson {
    type: DoctorMediaContentType
    url: string
    description: string
}

class DoctorMediaContent {
    constructor(
        public type: DoctorMediaContentType,
        public url: string,
        public description: string
    ) {}
    
    public static fromJson(json: IDoctorMediaContentJson) {
        return new DoctorMediaContent(
            json.type,
            json.url,
            json.description
        )
    }
}

export default DoctorMediaContent